import React, { useContext, useState } from 'react'
import Sidebar from '../Sidebar'
import * as S from './styles'
import { useAuth } from '../../contexts/auth'

function Header() {
  const { logout } = useAuth()
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  return (
    <S.Container>
      <S.Hamburguer onClick={showSidebar} />
      {sidebar && <Sidebar setSidebar={setSidebar} />}
      <S.ButtonExit onClick={logout}>Sair</S.ButtonExit>
    </S.Container>
  )
}

export default Header
