import React, { useContext, useState } from 'react'

import * as S from './styles'
import { useNavigate } from 'react-router-dom'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { createUser } from '../../../services/users'
import { toast } from 'react-toastify'
import { useAuth } from '../../../contexts/auth'
import { Autocomplete } from '@mui/material'

const schema = yup.object().shape({
  // trim spaces before and after

  name: yup.string().required('Nome é obrigatório'),
  email: yup
    .string()
    .trim()
    .email('Insira um e-mail válido')
    .required('E-mail é obrigatório'),

  //password required minimum 6 characters
  password: yup
    .string()
    .min(6, 'Senha deve ter no mínimo 6 caracteres')
    .required('Senha é obrigatória'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Senhas não conferem')
})

function AddUser() {
  const navigate = useNavigate()
  const { user } = useAuth()

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(schema)
  })

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [assuntos, setAssuntos] = useState([])

  const options = [
    { value: 'Denúncia de Assédio', label: 'Denúncia de Assédio' },
    { value: 'Denúncia de Assédio RH', label: 'Denúncia de Assédio RH' },
    {
      value: 'Denúncia de Assédio Diretoria',
      label: 'Denúncia de Assédio Diretoria'
    },
    {
      value: 'Denúncia de Assédio Diretor Geral',
      label: 'Denúncia de Assédio Diretor Geral'
    },
    { value: 'Benefícios', label: 'Benefícios' },
    { value: 'Folha', label: 'Folha' },
    { value: 'Outros', label: 'Outros' },
    { value: 'EPI', label: 'Solicitação de EPI' }
  ]

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const onSubmit = async data => {
    const response = await createUser({
      ...data,
      ecossistema_fornecedor: user.ecossistema_fornecedor,
      assuntos: assuntos.map(item => item.value)
    })
    if (response.status === 201) {
      toast.success('Usuário criado com sucesso!')
      reset({ name: '', email: '', password: '', confirmPassword: '' })
      navigate('/users')
    } else if (response.response.data.error === 'Email já cadastrado') {
      setError('email', {
        type: 'manual',
        message: 'Email já cadastrado'
      })
      toast.error('Email já cadastrado')
    }
  }
  return (
    <S.Container>
      <S.BackButton onClick={() => navigate('/users')}>
        <S.BackIcon />
        Cadastrar usuários
      </S.BackButton>

      <S.Content>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            fullWidth
            label="Nome"
            {...register('name')}
            autoComplete="off"
            error={errors.name ? true : false}
            required
          />
          <TextField
            fullWidth
            label="E-mail"
            {...register('email')}
            autoComplete="off"
            error={errors.email ? true : false}
            required
          />

          <FormControl fullWidth error variant="standard">
            <TextField
              fullWidth
              label="Senha"
              {...register('password')}
              autoComplete="off"
              error={errors.password ? true : false}
              required
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormHelperText>{errors.password?.message}</FormHelperText>
          </FormControl>

          {/* confirmPassword */}
          <FormControl fullWidth error variant="standard">
            <TextField
              fullWidth
              label="Confirmar senha"
              {...register('confirmPassword')}
              autoComplete="off"
              error={errors.confirmPassword ? true : false}
              required
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormHelperText>{errors.confirmPassword?.message}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error variant="standard">
            <Autocomplete
              value={assuntos}
              multiple
              options={options}
              getOptionLabel={option => option.label}
              onChange={(event, value) => {
                setAssuntos(value)
              }}
              renderInput={params => (
                <TextField
                  sx={{
                    backgroundColor: '#fff'
                  }}
                  {...params}
                  label="Assuntos"
                  placeholder="Selecione"
                />
              )}
            />
            <FormHelperText>{errors.status?.message}</FormHelperText>
          </FormControl>
          <div />
          <S.ButtonCad variant="contained" type="submit">
            Cadastrar
          </S.ButtonCad>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default AddUser
