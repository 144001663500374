import React, { useContext, useEffect, useState } from 'react'

import * as S from './styles'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { set, useForm } from 'react-hook-form'
import * as yup from 'yup'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { updateUser, verifyEmail } from '../../../services/users'
import { toast } from 'react-toastify'
import { useAuth } from '../../../contexts/auth'
import { Autocomplete } from '@mui/material'

const schema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  email: yup
    .string()
    .trim()
    .email('Insira um e-mail válido')
    .required('E-mail é obrigatório')
})

const schemaPassword = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Senha deve ter no mínimo 6 caracteres')
    .required('Senha é obrigatória'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Senhas não conferem')
})

function EditUser() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { state } = useLocation()

  const options = [
    { value: 'Denúncia de Assédio', label: 'Denúncia de Assédio' },
    { value: 'Denúncia de Assédio RH', label: 'Denúncia de Assédio RH' },
    {
      value: 'Denúncia de Assédio Diretoria',
      label: 'Denúncia de Assédio Diretoria'
    },
    {
      value: 'Denúncia de Assédio Diretor Geral',
      label: 'Denúncia de Assédio Diretor Geral'
    },
    { value: 'Benefícios', label: 'Benefícios' },
    { value: 'Folha', label: 'Folha' },
    { value: 'Outros', label: 'Outros' },
    { value: 'EPI', label: 'Solicitação de EPI' }
  ]

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(schema)
  })

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    setValue: setValuePassword,
    reset: resetPasswod,
    formState: { errors: errorsPassword }
  } = useForm({
    resolver: yupResolver(schemaPassword)
  })

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [assuntos, setAssuntos] = useState([])

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const onSubmit = async data => {
    try {
      if (state.email !== data.email) {
        const emailExists = await verifyEmail({email: data.email})
        if (emailExists && emailExists.response?.status === 400) {
          toast.error('E-mail já existente')
          setError('email', {
            type: 'manual',
            message: 'E-mail já existente'
          })
          return
        }
      }

      const response = await updateUser(state.id, {
        ...data,
        assuntos: assuntos.map(item => item.value)
      })

      if (response.status === 200) {
        toast.success('Usuário atualizado com sucesso!')
        navigate('/users')
      }
    } catch (error) {
      toast.error('Erro ao atualizar usuário')
    }
  }

  const onChangePassword = async data => {
    try {
      const response = await updateUser(state.id, { ...data })

      if (response.status === 200) {
        toast.success('Usuário atualizado com sucesso!')
        navigate('/users')
      }
    } catch (error) {
      toast.error('Erro ao atualizar usuário')
    }
  }

  useEffect(() => {
    setValue('name', state.name)
    setValue('email', state.email)
    if (state.assuntos) {
      const arrAssuntos = []
      state.assuntos.map(item => {
        arrAssuntos.push({
          value: item,
          label: item === 'EPI' ? 'Solicitação de EPI' : item
        })
      })
      setAssuntos(arrAssuntos)
    }
  }, [])

  return (
    <S.Container>
      <S.BackButton onClick={() => navigate('/users')}>
        <S.BackIcon />
        Editar usuário
      </S.BackButton>

      <S.Content>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            fullWidth
            label="Nome"
            {...register('name')}
            autoComplete="off"
            error={errors.name ? true : false}
            required
          />
          <TextField
            fullWidth
            label="E-mail"
            {...register('email')}
            autoComplete="off"
            error={errors.email ? true : false}
            required
          />

          <FormControl fullWidth error variant="standard">
            <Autocomplete
              value={assuntos}
              multiple
              options={options}
              getOptionLabel={option => option.label}
              onChange={(event, value) => {
                setAssuntos(value)
              }}
              renderInput={params => (
                <TextField
                  sx={{
                    backgroundColor: '#fff'
                  }}
                  {...params}
                  label="Assuntos"
                  placeholder="Selecione"
                />
              )}
            />
            <FormHelperText>{errors.status?.message}</FormHelperText>
          </FormControl>
          <div />
          <S.ButtonCad variant="contained" type="submit">
            Salvar alterações
          </S.ButtonCad>
        </S.Form>

        <S.Form
          style={{ marginTop: 30 }}
          onSubmit={handleSubmitPassword(onChangePassword)}
        >
          <FormControl fullWidth error variant="standard">
            <TextField
              fullWidth
              label="Senha"
              {...registerPassword('password')}
              autoComplete="off"
              error={errorsPassword.password ? true : false}
              required
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormHelperText>{errorsPassword.password?.message}</FormHelperText>
          </FormControl>

          {/* confirmPassword */}
          <FormControl fullWidth error variant="standard">
            <TextField
              fullWidth
              label="Confirmar senha"
              {...registerPassword('confirmPassword')}
              autoComplete="off"
              error={errorsPassword.confirmPassword ? true : false}
              required
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormHelperText>
              {errorsPassword.confirmPassword?.message}
            </FormHelperText>
          </FormControl>

          <S.ButtonCad variant="contained" type="submit">
            Alterar senha
          </S.ButtonCad>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default EditUser
