import styled from 'styled-components'
import { TbFileUpload } from 'react-icons/tb'
import { Button } from '@mui/material'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(82, 87, 92, 0.36);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
`

export const Modal = styled.div`
  width: 560px;
  height: 308px;
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h1`
  font-weight: 400;
  font-size: 18px;
  margin-top: 20px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
`
export const ButtonSend = styled(Button).attrs({
  variant: 'contained'
})`
  && {
    text-transform: none;
    width: 160px;
    height: 34px;
    color: #fff;
    background-color: #7F5CD1;

    &:hover {
      opacity: 0.8;
      background: #7F5CD1;
      color: #fff;
      border: 1px solid #7F5CD1;
    }
  }
`

export const ButtonCancel = styled(Button).attrs({
  variant: 'outlined'
})`
  && {
    text-transform: none;
    width: 160px;
    height: 34px;
    color: #e43935;
    border: 1px solid #e43935;

    &:hover {
      opacity: 0.8;
      background: #e43935;
      color: #fff;
      border: 1px solid #e43935;
    }
  }
`

export const Dropzone = styled.div`
  height: 150px;
  width: 90%;
  border-radius: 12px;
  border: 1px dashed #bfbfbf;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const DropzoneText = styled.p`
  width: 70%;
  text-align: center;
  font-size: 14px;
  color: #bfbfbf;
`

export const DropzoneTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
`

export const DropzoneIcon = styled(TbFileUpload)`
  color: #333;
  font-size: 30px;
  margin-bottom: 10px;
`
