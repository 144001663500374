import React from 'react'
import Routes from './routes'
import { AuthProvider } from './contexts/auth'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <Routes />
      <ToastContainer autoClose={3000} />
    </AuthProvider>
  </BrowserRouter>
)
export default App
