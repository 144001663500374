import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from '../pages/Login'
import ForgotPassword from '../pages/ForgotPassword'
import ChangePassword from '../pages/ChangePassword'

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />}>
        <Route exact path="*" element={<h1>Not Found</h1>} />
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword />}>
        <Route exact path="*" element={<h1>Not Found</h1>} />
      </Route>
      <Route path="/reset-password" element={<ChangePassword />}>
        <Route exact path="*" element={<h1>Not Found</h1>} />
      </Route>
    </Routes>
  )
}

export default PublicRoutes
