import React from 'react'
import styled from 'styled-components'

export const WrapperGrid = styled.div`
  margin-top: 20px;
  /* width: 700px; */
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
`
