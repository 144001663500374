import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* grid-template-columns: 1fr; */
  min-height: 600px;
`
export const LeftSide = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`
export const RightSide = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`
export const TopSide = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #ccc;
  height: 40px;
`
export const Chat = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  grid-gap: 8px;
  overflow: auto;
  height: 500px;
  background-color: #f4f8fa;
`
export const Message = styled.div`
  display: flex;
  flex-direction: column;
  animation: pop 0.25s ease;

  @keyframes pop {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  .sender_name {
    text-align: right;
    color: #808080;
    margin-bottom: 5px;
    margin-top: 7px;
    font-size: 14px;
  }

  &.admin {
    justify-content: flex-end;
  }

  .admin,
  .user {
    display: flex;
    grid-gap: 8px;

    .span-message {
      padding: 5px;
      font-size: 14px;
      border-radius: 7px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      padding: 10px;
      padding-right: 50px;
      max-width: 400px;

      p {
        font-size: 12px;
        font-weight: 300;
        color: #666668;
        text-align: right;
        margin: 0;
        margin-right: -40px;
      }
    }
  }

  .admin {
    flex-direction: row-reverse;

    .span-message {
      background-color: #7F5CD1;
      color: #ffffff;
      padding-right: 50px;
      p {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }

  .user {
    .span-message {
      background-color: #f2f2f7;
      color: #000;
    }
  }

  .visualized {
    width: 55px;
    height: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #f2f2f7;
    border-radius: 5px;
    margin-top: 5px;

    font-size: 10px;
    color: #808080;
  }
`

export const BotSide = styled.form`
  width: 100%;
  height: 60px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
`
export const SendMessage = styled.input`
  width: 90%;
  padding: 5px;
  height: 35px;
  border-radius: 5px;
  background-color: #f3f3f3;
  border: none;
`
export const DetailsContacts = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 5px;
`
export const RowBetween = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`
export const Text = styled.p`
  font-size: 13px;
  color: #000;
`
export const TextSmall = styled.p`
  font-size: 13px;
  color: #808080;
  margin-right: 5px;
`
export const TextArea = styled.div`
  width: 100%;
  padding: 5px 10px;
  min-height: 180px;
  max-height: 240px;
  border-radius: 5px;
  border: 1px solid #dbdde0;
  font-size: 12px;
  overflow-y: auto;
`
export const Button = styled.div`
  cursor: pointer;
  width: 157px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.finalized ? '#808080' : '#007BFF')};
  border-radius: 5px;
  border: none;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
`
export const DayBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const Day = styled.div`
  display: flex;
  margin: 5px 0;
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
`
export const TextDay = styled.p`
  font-size: 12px;
  color: #808080;
  text-align: center;
  margin: 0;
`
