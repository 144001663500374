import React from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../contexts/auth'

import * as S from './styles'
import { getUsers, deleteUser } from '../../../services/users'

function Modal(props) {
  const { user } = useAuth()
  const handleDelete = async () => {
    const response = await deleteUser(props.selectedUser)
    if (response.status === 200) {
      if (user._id) {
        getUsers(user._id).then(response => {
          props.setRowsUsers(response)
          toast.success('Usuário deletado com sucesso!')
          props.setShowModal(false)
        })
      }
    }
  }
  return (
    <S.Container>
      <S.Modal>
        <S.IconAlert />
        <S.Title>Você tem certeza que deseja exluir esse usuário?</S.Title>
        <S.ButtonWrapper>
          <S.ButtonCancel onClick={() => props.setShowModal(false)}>
            Cancelar
          </S.ButtonCancel>
          <S.ButtonDelete onClick={handleDelete}>Excluir</S.ButtonDelete>
        </S.ButtonWrapper>
      </S.Modal>
    </S.Container>
  )
}

export default Modal
