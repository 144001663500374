import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DotLoader from 'react-spinners/DotLoader'
import { useAuth } from '../../contexts/auth'
import { toast } from 'react-toastify'

function login() {
  const [showInput, setShowInput] = useState(false)
  const [loading, setLoading] = useState(false)
  const { login } = useAuth()

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Insira um e-mail válido')
      .required('E-mail é obrigatório'),
    password: yup.string().required('Senha é obrigatória')
  })

  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful },
    formState: { errors },
    reset,
    setError
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    // setLoading(true)
    // const response = await signIn(data.email, data.password)
    // console.log(response)
    // if (response?.error)
    //   setError('login', {
    //     type: 'manual',
    //     message: 'Usuário ou senha inválidos'
    //   })
    // setLoading(false)
    setLoading(true)
    const response = await login(data.email, data.password)
    // console.log(response)
    if (response?.error)
      setError('login', {
        type: 'manual',
        message: 'Usuário ou senha inválidos'
      })
    setLoading(false)
  }

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ email: '', password: '' })
    }
  }, [formState, reset])

  useEffect(() => {
    const tokenExpired = window.location.search.includes('tokenExpired')
    if (tokenExpired) {
      toast.error('Sua sessão expirou, faça login novamente')
    }
    const changedPassword = window.location.search.includes('changedPassword')
    if (changedPassword) {
      toast.success('Senha alterada com sucesso!')
    }
  }, [])

  return (
    <S.Container>
      <S.ContentLeft>
        <S.Content></S.Content>
      </S.ContentLeft>
      <S.ContentRight>
        <S.LoginWrapper onSubmit={handleSubmit(onSubmit)}>
          <S.LoginTitle>Bem-vindo, faça seu login</S.LoginTitle>
          <S.Input
            {...register('email')}
            type="email"
            placeholder="E-mail"
            name="email"
          />
          <S.Error>{errors.email?.message}</S.Error>
          <S.Error>{errors.login?.message}</S.Error>

          <S.InputWrapper>
            <S.Input
              {...register('password')}
              placeholder="Senha"
              type={showInput ? 'text' : 'password'}
              name="password"
              id="password"
            />

            <S.EyeButton onClick={() => setShowInput(!showInput)}>
              {showInput ? <S.EyeIcon /> : <S.EyeSlashIcon />}
            </S.EyeButton>
          </S.InputWrapper>
          <S.Error>{errors.password?.message}</S.Error>
          <S.ForgotPassword to="/forgot-password">
            Esqueceu sua senha?
          </S.ForgotPassword>
          <S.Button disabled={false} onClick={handleSubmit}>
            {loading ? <DotLoader size={30} color="#fff" /> : 'Entrar'}
          </S.Button>
          <S.Separator>
            <S.OrText>Ou</S.OrText>
          </S.Separator>
          <S.TextWrapper>
            <S.Text>
              Não possui uma conta? Entre em contato pelo <br />
              <a
                href="https://wa.me/5548992192700"
                target="_blank"
                rel="noreferrer"
              >
                Whatsapp
              </a>
            </S.Text>
          </S.TextWrapper>
        </S.LoginWrapper>
      </S.ContentRight>
    </S.Container>
  )
}

export default login
