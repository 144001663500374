import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DotLoader from 'react-spinners/DotLoader'
import { useAuth } from '../../contexts/auth'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { resetPassword } from '../../services/users'

function login() {
  const [showInput, setShowInput] = useState(false)
  const [loading, setLoading] = useState(false)

  const params = useParams()

  const hash = params.hash || window.location.search.split('?hash=')[1]

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, 'Senha deve ter no mínimo 6 caracteres')
      .required('Senha é obrigatória'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Senhas não conferem')
  })

  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful },
    formState: { errors },
    reset,
    setError
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    setLoading(true)
    const response = await resetPassword({ hash, nova_senha: data.password })
    if (response.status === 200) {
      window.location.href = '/?changedPassword=true'
    } else toast.error('Erro ao alterar senha, ou código expirado!')
    setLoading(false)
  }

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ confirmPassword: '', password: '' })
    }
  }, [formState, reset])

  useEffect(() => {
    const tokenExpired = window.location.search.includes('tokenExpired')
    if (tokenExpired) {
      toast.error('Sua sessão expirou, faça login novamente')
    }
  }, [])

  return (
    <S.Container>
      <S.ContentRight>
        <S.LoginWrapper onSubmit={handleSubmit(onSubmit)}>
          <S.LoginTitle>
            Escolha uma nova senha <br />
          </S.LoginTitle>
          <S.InputWrapper>
            <S.Input
              {...register('password')}
              placeholder="Senha"
              type={showInput ? 'text' : 'password'}
              name="password"
              id="password"
            />

            <S.EyeButton onClick={() => setShowInput(!showInput)}>
              {showInput ? <S.EyeIcon /> : <S.EyeSlashIcon />}
            </S.EyeButton>
          </S.InputWrapper>
          <S.Error>{errors.password?.message}</S.Error>

          <S.InputWrapper>
            <S.Input
              {...register('confirmPassword')}
              placeholder="Confirmar senha"
              type={showInput ? 'text' : 'password'}
              name="confirmPassword"
              id="confirmPassword"
            />

            <S.EyeButton onClick={() => setShowInput(!showInput)}>
              {showInput ? <S.EyeIcon /> : <S.EyeSlashIcon />}
            </S.EyeButton>
          </S.InputWrapper>
          <S.Error>{errors.confirmPassword?.message}</S.Error>

          <S.Button disabled={false} onClick={handleSubmit}>
            {loading ? <DotLoader size={30} color="#fff" /> : 'Salvar senha'}
          </S.Button>
        </S.LoginWrapper>
      </S.ContentRight>
    </S.Container>
  )
}

export default login
