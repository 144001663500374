import api from './api'

export const getEcossistemaCompanies = async id => {
  try {
    const { data } = await api.get(`auth/ecossistema-empresas/ecossistema/${id}`)
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

export const getEcossistemaFornecedor = async id => {
  try {
    const { data } = await api.get(`auth/ecossistema-fornecedores/${id}`)
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

