import React from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../contexts/auth'

import * as S from './styles'

function Modal(props) {
  return (
    <S.Container>
      <S.Modal>
        <S.IconAlert />
        <S.Title>Você tem certeza que deseja enviar o arquivo {props.file.name}?</S.Title>
        <S.ButtonWrapper>
          <S.ButtonCancel onClick={() => props.setShowModal(false)}>
            Cancelar
          </S.ButtonCancel>
          <S.ButtonDelete onClick={() => {props.sendAttachment('', props.file); props.setShowModal(false)}}>Enviar</S.ButtonDelete>
        </S.ButtonWrapper>
      </S.Modal>
    </S.Container>
  )
}

export default Modal
