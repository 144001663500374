import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'
import * as S from './styles'
import Chat from '../../../components/Chat'
import {
  createChat,
  getOneChat,
  putOneChat,
  sendNewMessage,
  putChatPartner
} from '../../../services/attendance'
import { getEcossistemaCompanies } from '../../../services/company'
import { getBaseUrl } from '../../../services/api'
import { useAuth } from '../../../contexts/auth'
import { toast } from 'react-toastify'

import FinalizeConfirm from '../FinalizeModal'
import AttachmentConfirm from '../AttachmentModal'
import { set } from 'react-hook-form'

function ChatPartners() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)

  // const { data_id } = state.id
  // const [data_id, setDataId] = useState('')
  // const [loading, setLoading] = useState(false)
  const [idChat, setIdChat] = useState(undefined)
  const [idUser, setIdUser] = useState(undefined)
  const [finalized, setFinalized] = useState(false)
  // const [chatDetails, setChatDetails] = useState({})
  const [messages, setMessages] = useState([])
  const [file, setFile] = useState(null)
  const [visualizado_user, setVisualizadoUser] = useState(false)
  const [clientSocket, setClientSocket] = useState(null)
  const [firstTime, setFirstTime] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showModalAttachment, setShowModalAttachment] = useState(false)
  const [empresa, setEmpresa] = useState(null)

  useEffect(() => {
    const fetchEmpresa = async () => {
      const res = await getEcossistemaCompanies(user.ecossistema_fornecedor)
      setEmpresa(res[0]?.empresa._id)
    }
    fetchEmpresa()
  }, [])  

  // carrega todas as mensagens do chat
  const loadMessage = async id_chat => {
    try {
      const chat = await getOneChat(id_chat)
      setIdUser(chat?.usuario)
      if (chat) {
        setMessages(chat.mensagens)
        setVisualizadoUser(chat.visualizado_user)
        setFinalized(chat.finalizado)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadNewMessage = async id_chat => {
    if (firstTime) {
      setFirstTime(false)
      setTimeout(async () => {
        setLoading(true)
        try {
          const params = {}
          const loader = false
          const chat = await getOneChat(id_chat, params, loader)
          if (chat) {
            setMessages(chat.mensagens)
            setVisualizadoUser(chat.visualizado_user)
            setFinalized(chat.finalizado)
          }
        } catch (error) {
          console.error(error)
        }
        setLoading(false)
      }, 1000)
    }
  }

  // envia a mensagem do admin
  const sendMessage = async (text, attachment) => {
    const dateNotification = new Date()
    const data = {
      usuario: idUser,
      data_agendamento: dateNotification,
      tipo: 'PARTNER',
      titulo: 'Nova mensagem',
      mensagem: 'Você recebeu uma nova mensagem.',
      chat: idChat,
      recorrente: false
    }
    if (idChat) {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append('attachment', attachment)
        formData.append(
          'data',
          JSON.stringify({
            mensagem: text,
            sender: 'admin',
            partner: user._id,
            sender_name: user.name,
            notification: true,
            notification_data: data
          })
        )
        const response = await sendNewMessage(idChat, formData)
        if (response && response.update === 1) {
          state.partner = user._id
          await putChatPartner(state.partnerChat, { partner: user._id })
          const socketObject = {
            chat: idChat,
            user: user._id
          }
          // dispara evento para o socket com nova mensagem
          clientSocket.emit('sendMessage', socketObject)
          loadMessage(idChat)
        }
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    } else {
      const formData = {
        usuario: idUser,
        empresa: empresa,
        ecossistema_fornecedor: user.ecossistema_fornecedor,
        descricao: 'Fale com o RH',
        message: text,
        assunto: 'RH',
        sender: 'admin',
        feature: 'RH',
      }
      try {
        const response = await createChat(formData)
        if (response) {
          setIdChat(response.chat.id)
          loadMessage(response.chat.id)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    // init socket
    const client = io.connect(getBaseUrl(), {
      extraHeaders: {
        authorization: `Bearer ${localStorage.getItem('@Partner:token')}`
      }
    })
    client.on('connect', () => {
      //only hour
      const date = new Date()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
      const time = `${hour}:${minute}:${second}`
      // console.log('conectado ' + time)
    })
    setClientSocket(client)
    // console.log(client)
    if (state.userId) {
      setIdUser(state.userId)
    }

    // carrega chat
    if (state.id) {
      // setDataId(state.id)
      setIdChat(state.id)
      loadMessage(state.id)
    }
  }, [state.id])

  useEffect(() => {
    if (clientSocket) {
      clientSocket.on('receivedMessage', () => {
        idChat && loadNewMessage(idChat)
      })

      return () => clientSocket.off('receivedMessage')
    }
  }, [idChat, clientSocket])

  const reloadMessages = () => {
    idChat && loadMessage(idChat)
  }

  const finalizeChat = async () => {
    try {
      const response = await putOneChat(state.id, {
        usuario: idUser,
        finalizado: true
      })
      if (response && response.update === 1) {
        setShowModal(false)
        toast.success('Chat finalizado com sucesso!')
        navigate('/attendance')

        // Toast.success().then()
        // setTimeout(() => {
        //   loadMessage(idChat)
        // }, [2000])
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    // setDataId(state.id)
  }, [state])

  return (
    <S.Container>
      {showModal && (
        <FinalizeConfirm
          finalizeChat={finalizeChat}
          setShowModal={setShowModal}
        />
      )}
      {showModalAttachment && (
        <AttachmentConfirm
          sendAttachment={sendMessage}
          file={file}
          setShowModal={setShowModalAttachment}
        />
      )}

      <S.Title onClick={() => navigate('/attendance')}>
        <S.BackButton />
        Chat
      </S.Title>

      <Chat
        messages={messages}
        chatDetails={state}
        sendMessage={sendMessage}
        reloadMessages={reloadMessages}
        visualizado_user={visualizado_user}
        finalizado={finalized}
        finalizeChat={setShowModal}
        loading={loading}
        setFile={setFile}
        confirmAttachment={setShowModalAttachment}
      />
    </S.Container>
  )
}

export default ChatPartners
