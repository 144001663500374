import React from 'react'
import * as M from '@mui/material'

import { styled } from '@mui/system'

const TextField = styled(M.TextField)`
  width: 100%;
`

const CustomTextField = ({ name, ...props }) => {
  return (
    <TextField
      color="secondary"
      variant="outlined"
      {...props}
      style={{ background: '#fff' }}
    />
  )
}

export default CustomTextField
