import api from './api'

export const signIn = async (email, password) => {
  try {
    const { data } = await api.post('/partners/signin', { email, password })
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}
