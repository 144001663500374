import React, { createContext, useContext, useEffect, useState } from 'react'
import { signIn } from '../services/login'
import api from '../services/api'
import { useNavigate } from 'react-router-dom'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null)
  const [user, setUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  async function login(email, password) {
    const response = await signIn(email, password)
    if (response.token) {
      localStorage.setItem('@Partner:token', response.token)
      localStorage.setItem('@Partner:user', JSON.stringify(response))
      setUser(JSON.parse(localStorage.getItem('@Partner:user')))
      setToken(localStorage.getItem('@Partner:token'))
      setIsAdmin(JSON.parse(localStorage.getItem('@Partner:user'))?.admin)
      api.defaults.headers.common['Authorization'] = `Bearer ${response.token}`
      setLoading(false)
    }
    setLoading(false)
    return response
  }

  function logout() {
    // console.log('logout')
    localStorage.removeItem('@Partner:token')
    localStorage.removeItem('@Partner:user')
    setToken(false)
    navigate('/')
  }

  useEffect(() => {
    //persistencia
    const token = localStorage.getItem('@Partner:token')
    //get user from localStorage
    const user = localStorage.getItem('@Partner:user')

    if (token) {
      setToken(token)
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
    if (user) {
      setUser(JSON.parse(user))
      setIsAdmin(JSON.parse(user)?.admin)
    }
    setLoading(false)
  }, [])

  // console.log('loading', loading)
  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!token,
        isAdmin,
        user,
        loading,
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
