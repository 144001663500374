import React from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../contexts/auth'

import * as S from './styles'

function Modal(props) {
  return (
    <S.Container>
      <S.Modal>
        <S.IconAlert />
        <S.Title>Você tem certeza que deseja finalizar esse chat?</S.Title>
        <S.ButtonWrapper>
          <S.ButtonCancel onClick={() => props.setShowModal(false)}>
            Cancelar
          </S.ButtonCancel>
          <S.ButtonDelete onClick={() => props.finalizeChat()}>Finalizar</S.ButtonDelete>
        </S.ButtonWrapper>
      </S.Modal>
    </S.Container>
  )
}

export default Modal
