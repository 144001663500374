import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 22px;
`
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 114px; */
  height: 38px;
  width: 150px;

  background: #7F5CD1;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  flex-direction: column;

  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  /* height: 100%; */
  /* margin-top: 20px; */
  padding: 50px;
`

export const WrapperInput = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SubTitle = styled.h2`
  font-size: 14px;
  color: #212529;

  font-weight: 600;
`
