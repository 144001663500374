import api from './api'

export const getChats = async ecossistema_fornecedor => {
  try {
    const { data } = await api.get('auth/chats/tipo', {
      params: {
        tipos: ['partner'],
        ecossistema_fornecedor
      }
    })
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

export const getChatsFiltered = async payload => {
  const {
    ecossistema_fornecedor,
    status_chat,
    codigo,
    username_matricula,
    empresa_razao,
    empresa_filha,
    filial,
    atendente,
    assunto,
    data_inicio,
    data_fim,
    anonimo
  } = payload
  try {
    const { data } = await api.get('auth/chats/tipo', {
      params: {
        tipos: ['partner'],
        ecossistema_fornecedor: ecossistema_fornecedor,
        username_matricula,
        empresa_filha,
        filial,
        empresa_razao,
        codigo,
        status_chat,
        atendente,
        assunto,
        data_inicio,
        data_fim,
        anonimo
      }
    })
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

export const getOneChat = async (id, params) => {
  try {
    const { data } = await api.get('auth/chats/' + id, params)
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

export const putOneChat = async (id, params) => {
  try {
    const { data } = await api.put('auth/chats/' + id, params)
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

export const putChatPartner = async (id, params) => {
  try {
    const { data } = await api.put('auth/chat-partners/' + id, params)
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

export const sendNewMessage = async (id, params) => {
  try {
    const { data } = await api.put(
      'auth/chats-nova-mensagem/partners/' + id,
      params
    )
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

export const createChat = async payload => {
  try {
    const { data } = await api.post('/auth/chat-partners', payload)
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}
