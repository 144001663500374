import styled from 'styled-components'
import background from '../../assets/login/background.png'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { isDisabled } from '@testing-library/user-event/dist/utils'
import { NavLink } from 'react-router-dom'

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #fff;
  /* width: 100%; */
  //mobile
  @media (max-width: 600px) {
    flex-direction: column;
  }
`
export const ContentLeft = styled.div`
  width: 50%;
  background: url(${background});
  background-color: #f4f8fa;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  //mobile
  @media (max-width: 600px) {
    width: 100%;
    height: 50%;
    min-height: 350px;
  }
`

export const Content = styled.div`
  padding-top: 85px;
  padding-left: 20%;

  @media (max-width: 1024px) {
    padding-left: 10%;
  }
  @media (max-width: 768px) {
    padding-left: 5%;
  }
  //mobile
  @media (max-width: 600px) {
    width: 100%;
    padding-top: 5%;
  }
`

export const Logo = styled.img`
  width: 150px;
  height: 60px;
`

export const Title = styled.h1`
  font-size: 42px;
  font-weight: 700;
  color: #ffffff;
  max-width: 260px;
  margin-top: 50px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`
export const Subtitle = styled.h2`
  font-weight: 400;
  font-size: 32px;
  color: #ffffff;
  width: 90%;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
    width: 90%;
  }
`
export const ContentRight = styled.div`
  width: 50%;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  //mobile
  @media (max-width: 600px) {
    width: 100%;
    height: 50%;
    min-height: 450px;
  }
`
export const LoginWrapper = styled.form`
  border: 1px solid #e0e0e0;
  max-width: 500px;
  padding: 50px;
  border-radius: 14px;
`
export const LoginTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
  color: #503394;
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
`

export const Input = styled.input`
  height: 50px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-indent: 10px;
  color: #808080;
  font-size: 18px;
  background: transparent;
`

export const Error = styled.span`
  color: red;
  font-size: 12px;
`

export const EyeButton = styled.div`
  font-size: 22px;
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`

export const EyeIcon = styled(FaEye)`
  color: #808080;
`

export const EyeSlashIcon = styled(FaEyeSlash)`
  color: #808080;
`

export const ForgotPassword = styled(NavLink)`
  margin-top: 20px;
  font-size: 12px;
  display: flex;
  justify-content: right;
  cursor: pointer;
  color: #333;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`
export const Button = styled.button`
  /* background: #7F5CD1; */
  background: ${props =>
    props.disabled ? 'rgba(127, 92, 209, 0.2)' : '#7F5CD1'};
  border-radius: 5px;
  height: 48px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: 40px;

  &:hover {
    opacity: 0.8;
  }
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #e0e0e0;
  margin: 40px 0;
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OrText = styled.div`
  color: #808080;
  background: #fff;
  width: 50px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`

export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

export const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* display: flex;
  justify-content: center; */
  text-align: center;

  width: 100%;

  a {
    color: #1DAFAA;
    font-weight: 600;
    text-decoration: none;
  }
`
export const TextHighlight = styled.span`
  color: #7F5CD1;
  font-weight: 600;
  margin-left: 5px;
`
