import styled from 'styled-components'
import { MdArrowBackIosNew } from 'react-icons/md'

export const Container = styled.div`
  width: 100%;
`
export const Title = styled.button`
  font-weight: 600;
  font-size: 22px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: none;
  background: none;
  margin-bottom: 20px;

  & svg {
    margin-right: 10px;
  }
  &:hover {
    opacity: 0.5;
  }
`
export const BackButton = styled(MdArrowBackIosNew)``
