import React, { useEffect, useState, forwardRef } from 'react'
import * as S from './styles'
import { useAuth } from '../../contexts/auth'
import {  TextField, InputAdornment } from '@mui/material'
import { toast } from 'react-toastify'
import { AiOutlineSearch } from 'react-icons/ai'

import DataGridAttendance from '../../components/DataGrid'
import UploadModal from './UploadModal'

import { getStudents } from '../../services/students'
import { getEcossistemaCompanies } from '../../services/company'
import { uploadFile } from '../../services/students'


function Students() {
  const { user } = useAuth()

  const columns = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      bolder: true
    },
    {
      field: 'email',
      headerName: 'E-mail',
      align: 'center',
      headerAlign: 'center',
      width: 350
      // editable: true
    },
    {
      field: 'escola',
      headerName: 'Escola',
      align: 'center',
      headerAlign: 'center',
      width: 350
      // editable: true
    },
    {
      field: 'situacao',
      headerName: 'Status',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      // editable: true,
      // renderCell: buttonInitChat
      renderCell: params => {
        if (params.row.situacao === 'TERMO_PENDENTE')
          return (
            <S.buttonPending
              onClick={() => {
                setResponsavel(params.row.responsavel_aluno)
                setShowModal(true)
              }}
            >
              Upload do termo
            </S.buttonPending>
          )

        if (params.row.situacao === 'TERMO_ASSINADO')
          return (
            <S.buttonSigned  onClick={() => {
              setResponsavel(params.row.responsavel_aluno)
              setShowModal(true)
            }}>Termo Assinado</S.buttonSigned>
          )
      }
    }
  ]

  const [rowsData, setRowsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [responsavel, setResponsavel] = useState(false)
  const [reload, setReload] = useState(false)
  const [data, setData] = useState([])
  const [filter, setFilter] = useState([])

  const sendFile = async file => {
    const formData = new FormData()
    formData.append('termos', file)

    try {
      await uploadFile(responsavel, formData)

      toast.success('Arquivo enviado com sucesso')
      setShowModal(false)
      setReload(!reload)
    } catch (error) {
      console.error(error)
      toast.error('Erro ao enviar arquivo')
    }
  }

  useEffect(() => {
    setLoading(true)
    const fetchStudents = async () => {
      await getEcossistemaCompanies(user.ecossistema_fornecedor).then(res => {
        getStudents(res[0]?.empresa._id).then(response => {
          const newRows = response?.map((item, index) => {
            return {
              id: index,
              nome: item.nome,
              email: item.email,
              escola: item.escola,
              responsavel_aluno: item.responsavel_aluno,
              situacao: item.situacao
            }
          })
          setData(newRows)
          setRowsData(newRows)
          setLoading(false)
        })
      })
    }
    fetchStudents()
  }, [reload])
  

  return (
    <S.Container>
      {showModal && (
        <UploadModal setShowModal={setShowModal} uploadFile={sendFile} />
      )}

      <S.Title>Selecione o aluno</S.Title>
      <S.WrapperInputs>

        <TextField
          value={filter}
          variant='outlined'
          InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AiOutlineSearch style={{width: 28, height:28, color: '#ccc'}} />
            </InputAdornment>
          )}}
          onChange={e => setFilter(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
             
              const filtered = data.filter(item => {
                return item.nome.toLowerCase().includes(filter.toLowerCase()) || item.email.toLowerCase().includes(filter.toLowerCase()) || item.escola.toLowerCase().includes(filter.toLowerCase())
              })
              setRowsData(filtered)
            }
          }}
          name="filter"
          sx={{
            backgroundColor: '#fff'
          }}
          label="Pesquisar"
        />
      </S.WrapperInputs>

      <DataGridAttendance rows={rowsData} columns={columns} loading={loading} />
    </S.Container>
  )
}

export default Students
