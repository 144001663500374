import styled from 'styled-components'
import { MdArrowBackIosNew } from 'react-icons/md'
import { Button } from '@mui/material'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const BackButton = styled.button`
  font-weight: 600;
  font-size: 22px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: none;
  background: none;
  & svg {
    margin-right: 10px;
  }
  &:hover {
    opacity: 0.5;
  }
`
export const BackIcon = styled(MdArrowBackIosNew)``

export const Content = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  flex-direction: column;

  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  /* height: 100%; */
  margin-top: 20px;
  padding: 50px;
`

export const Form = styled.form`
  //form 4x2
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  //distancia 20px entre as colunas
  column-gap: 50px;
  //distancia 20px entre as linhas
  row-gap: 20px;
  width: 100%;
  height: 100%;
  padding: 20px;
  //items esquerda a direita
  justify-items: right;
`

export const ButtonCad = styled(Button).attrs({
  variant: 'contained'
})`
  && {
    background-color: #7F5CD1;
    text-transform: none;
    /* font-weight: 600; */
    font-size: 14px;
    /* width: 122px; */
    color: #fff;
    height: 45px;
    
    &:hover {
      opacity: 0.8;
      background-color: #7F5CD1;
      color: #fff;
    }
  }
`
