import React from 'react'
import Box from '@mui/material/Box'
import { DataGrid, GridToolbar, ptBR } from '@mui/x-data-grid'
import LinearProgress from '@mui/material/LinearProgress'
import * as S from './styles'
import { ptBR as corePtBR } from '@mui/material/locale'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { InputAdornment, TextField } from '@mui/material'
import { MdSearch } from 'react-icons/md'

const theme = createTheme(
  {
    // palette: {
    // primary: { main: '#1976d2' }
    // }
  },
  ptBR,
  corePtBR
)

export default function DataGridAttendance(props) {
  return (
    <S.WrapperGrid>
      <ThemeProvider theme={theme}>
        <DataGrid
          // components={{ Toolbar: GridToolbar }}
          slots={{
            loadingOverlay: LinearProgress
          }}
          rows={props.rows}
          columns={props.columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } }
          }}
          pageSizeOptions={[5, 10, 25]}
          loading={props.loading}
          density="standard"
          // clickableColumnHeaders={true}
          // onResizeColumnStart={(column, event) => {
          //   // Salva o valor original da largura da coluna
          //   column.originalWidth = column.width
          // }}
        />
      </ThemeProvider>
    </S.WrapperGrid>
  )
}
