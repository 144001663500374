import api from './api'

export const getPartnerById = async id => {
  try {
    const { data } = await api.get(`auth/partners/${id}`)
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

// nova senha
export const changePassword = async (id, password) => {
  try {
    const { data } = await api.put(`auth/partners/${id}/change-password`, {
      password
    })
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}
