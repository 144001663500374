import styled from 'styled-components'
import background from '../../assets/login/background.png'
import { NavLink } from 'react-router-dom'


export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #fff;
  /* width: 100%; */
  //mobile
  @media (max-width: 600px) {
    flex-direction: column;
  }
`
export const ContentLeft = styled.div`
  width: 50%;
  background: url(${background});
  background-color: #f4f8fa;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  //mobile
  @media (max-width: 600px) {
    width: 100%;
    height: 50%;
    min-height: 350px;
  }
`

export const Content = styled.div`
  padding-top: 85px;
  padding-left: 20%;

  @media (max-width: 1024px) {
    padding-left: 10%;
  }
  @media (max-width: 768px) {
    padding-left: 5%;
  }
  //mobile
  @media (max-width: 600px) {
    width: 100%;
    padding-top: 5%;
  }
`

export const Logo = styled.img`
  width: 150px;
  height: 60px;
`

export const Title = styled.h1`
  font-size: 42px;
  font-weight: 700;
  color: #ffffff;
  max-width: 260px;
  margin-top: 50px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`
export const Subtitle = styled.h2`
  font-weight: 400;
  font-size: 32px;
  color: #ffffff;
  width: 90%;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 22px;
    width: 90%;
  }
`
export const ContentRight = styled.div`
  width: 50%;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  //mobile
  @media (max-width: 600px) {
    width: 100%;
    height: 50%;
    min-height: 450px;
  }
`
export const LoginWrapper = styled.form`
  border: 1px solid #e0e0e0;
  max-width: 500px;
  padding: 50px;
  border-radius: 14px;
`
export const LoginTitle = styled(NavLink)`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 30px;
  color: #503394;
  cursor: pointer;
  text-decoration: none;
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
`

export const Input = styled.input`
  height: 50px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-indent: 10px;
  color: #808080;
  font-size: 18px;
  background: transparent;
  margin-top: 30px;
`

export const Error = styled.span`
  color: red;
  font-size: 12px;
`

export const Button = styled.button`
  /* background: #7F5CD1; */
  background: ${props =>
    props.disabled ? 'rgba(127, 92, 209, 0.2)' : '#7F5CD1'};
  border-radius: 5px;
  height: 48px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: 40px;

  &:hover {
    opacity: 0.8;
  }
`

export const Text = styled.span`
  font-weight: 500;
  color: #999;
  font-size: 16px;
  line-height: 17px;
  /* display: flex;
  justify-content: center; */
  text-align: center;

  width: 100%;
`
