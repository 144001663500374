import React, { useEffect, useRef, useState } from 'react'
import * as S from './styles'
import { changePassword, getPartnerById } from '../../services/account'

import { useForm } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { toast } from 'react-toastify'
import { DotLoader } from 'react-spinners'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Insira um e-mail válido')
    .required('E-mail é obrigatório'),
  // oldPassword: yup.string().required('Senha é obrigatória'),
  newPassword: yup.string().required('Senha é obrigatória'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Senhas não conferem')
})

function Account() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  // const [values, setValues] = useState({
  //   showOldPassword: false,
  //   showPassword: false,
  //   showConfirmPassword: false
  // })

  // const handleClickShowPassword = input => {
  //   input === 'oldPassword' &&
  //     setValues({ ...values, showOldPassword: !values.showOldPassword })
  //   input === 'password' &&
  //     setValues({ ...values, showPassword: !values.showPassword })
  //   input === 'confirmPassword' &&
  //     setValues({ ...values, showConfirmPassword: !values.showConfirmPassword })
  // }

  // const [showOldPassword, setShowOldPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [partner, setPartner] = useState({})
  const [loading, setLoading] = useState(false)

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  useEffect(() => {
    const partner = JSON.parse(localStorage.getItem('@Partner:user'))
    if (partner) {
      setPartner(partner)
      getPartnerById(partner._id).then(response => {
        setValue('email', response?.email)
      })
    }
  }, [])

  const onSubmit = async data => {
    setLoading(true)
    // console.log(data)
    const response = await changePassword(partner._id, data.newPassword)

    if (response?.update === 1) {
      toast.success('Senha alterada com sucesso!')
      reset({ newPassword: '', confirmPassword: '' })
    } else toast.error('Erro ao alterar senha, tente novamente!')

    setLoading(false)
  }

  return (
    <S.Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.TitleContainer>
          <S.Title>Conta</S.Title>

          <S.Button type="submit">
            {loading ? (
              <DotLoader size={30} color="#fff" />
            ) : (
              'Salvar alterações'
            )}
          </S.Button>
        </S.TitleContainer>
        <S.Content>
          <S.WrapperInput>
            <S.SubTitle>Dados da conta</S.SubTitle>
            <FormControl error variant="standard">
              {/* email */}
              <TextField
                label="E-mail"
                {...register('email')}
                defaultValue={' '}
                autoComplete="off"
                error={errors.email ? true : false}
                required
              />
              <FormHelperText>{errors.email?.message}</FormHelperText>
            </FormControl>

            <S.SubTitle>Alterar senha</S.SubTitle>
            {/* oldPassword */}
            {/* <FormControl error variant="standard">
              <TextField
                label="Senha atual"
                {...register('oldPassword')}
                autoComplete="off"
                error={errors.oldPassword ? true : false}
                required
                type={showOldPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormHelperText>{errors.oldPassword?.message}</FormHelperText>
            </FormControl> */}
            {/* password */}
            <FormControl error variant="standard">
              <TextField
                label="Nova senha"
                {...register('newPassword')}
                autoComplete="off"
                error={errors.newPassword ? true : false}
                required
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormHelperText>{errors.newPassword?.message}</FormHelperText>
            </FormControl>

            {/* confirmPassword */}
            <FormControl error variant="standard">
              <TextField
                label="Confirmar nova senha"
                {...register('confirmPassword')}
                autoComplete="off"
                error={errors.confirmPassword ? true : false}
                required
                type={showConfirmPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormHelperText>{errors.confirmPassword?.message}</FormHelperText>
            </FormControl>
          </S.WrapperInput>
        </S.Content>
      </form>
    </S.Container>
  )
}

export default Account
