import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'

export const Container = styled.div`
  /* border: 2px solid red; */
  position: fixed;
  height: 100%;
  width: 230px;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  padding-top: 25px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  left: ${props => (props.sidebar ? '0' : '100%')};
  animation: showSidebar 0.3s;

  @keyframes showSidebar {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 230px;
    }
  }
`

export const Close = styled(FaTimes)`
  /* color: #000000; */
  font-size: 20px;
  /* cursor: pointer; */
  margin-left: 30px;
  color: #808080;
  width: 30px;
  height: 30px;
  /* user-select: none; */
  /* index: 2; */
  /*  */

  &:hover {
    opacity: 0.8;
  }
`

export const Logo = styled.img`
  width: 100px;
  height: 40px;
  align-self: center;
`

export const Title = styled.h1`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #52575c;
  align-self: center;
  margin-bottom: 10px;
`
