import React from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import { IoMdAdd } from 'react-icons/io/'
import { MdEdit } from 'react-icons/md'
import { MdDelete } from 'react-icons/md'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 22px;
`

export const ButtonNewUser = styled(Button).attrs({
  endIcon: <IoMdAdd />,
  color: 'primary'
})`
  && {
    text-transform: none;
    width: 100%;
    height: 27px;
    background: #7F5CD1;
    color: #fff;
    width: 88px;
    height: 34px;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      opacity: 0.8;
      background: #7F5CD1;
      color: #fff;
    }
  }
`

export const WrapperActions = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
`

export const ButtonEdit = styled(MdEdit)`
  font-size: 30px;
  color: #a0a4a8;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
export const ButtonDelete = styled(MdDelete)`
  font-size: 30px;
  color: #e43935;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
