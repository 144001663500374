import React, { useEffect, useState, forwardRef } from 'react'
import * as S from './styles'
import DataGridAttendance from '../../components/DataGrid'
import { useNavigate } from 'react-router-dom'
import { getChats, getChatsFiltered } from '../../services/attendance'
import { getUser } from '../../services/users'
import { useAuth } from '../../contexts/auth'
import { Autocomplete, TextField, DateField } from '@mui/material'
import InputMask from 'react-input-mask'

import NewChatModal from './NewChatModal'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import moment from 'moment'

const options = [
  { value: 'ABERTO', label: 'Aberto' },
  { value: 'CONCLUIDO', label: 'Finalizado' },
  { value: 'NOVA_MENSAGEM', label: 'Nova Mensagem' }
]

const DateMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <InputMask
      {...other}
      mask="99/99/9999"
      maskChar={null}
      inputRef={ref}
      onChange={onChange}
    />
  )
})
const schema = yup.object().shape({})

function Attendance() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const columns = [
    {
      field: 'codigo',
      headerName: 'Cód.',
      width: 60,
      align: 'center',
      headerAlign: 'center',
      bolder: true
    },
    {
      field: 'matricula',
      headerName: 'Matrícula',
      align: 'center',
      headerAlign: 'center',
      width: 160
      // editable: true
    },
    {
      field: 'assunto',
      headerName: 'Assunto',
      align: 'center',
      headerAlign: 'center',
      width: 250
      // editable: true
    },
    {
      field: 'empresa_filha',
      headerName: 'Empresa',
      align: 'center',
      headerAlign: 'center',
      width: 200
      // editable: true
    },
    {
      field: 'filial',
      headerName: 'Filial',
      align: 'center',
      headerAlign: 'center',
      width: 200
      // editable: true
    },
    {
      field: 'dataPedido',
      headerName: 'Data do pedido',
      type: 'date',
      align: 'left',
      headerAlign: 'center',
      width: 160
      // editable: true
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      align: 'left',
      headerAlign: 'center',
      // editable: true,
      // renderCell: buttonInitChat
      renderCell: params => {
        if (params.row.status === 'AGUARDANDO')
          return (
            <S.buttonInitChat
              size="small"
              onClick={() =>
                navigate('/attendance/chat', {
                  state: {
                    id: params.row.id,
                    codigo: params.row.codigo,
                    usuario: params.row.usuario,
                    nickname: params.row?.nickname,
                    empresa: params.row.empresa,
                    dataPedido: params.row.dataPedido,
                    partnerChat: params.row.partnerChat,
                    partner: params.row.partner,
                    matricula: params.row.matricula,
                    filial: params.row.filial,
                    empresa_filha: params.row.empresa_filha,
                    departamento: params.row.departamento,
                    cargo: params.row.cargo,
                    email: params.row.email,
                    telefone: params.row.telefone,
                    data_admissao: params.row.data_admissao,
                    meio_ocorrencia: params.row.meio_ocorrencia,
                    periodicidade: params.row.periodicidade,
                    persiste: params.row.persiste,
                    provas: params.row.provas,
                    testemunhas: params.row.testemunhas,
                    anonimo: params.row.anonimo,
                    denunciado: params.row.denunciado,
                    nome_completo: params.row?.nome_completo,
                    matricula_denunciante: params.row?.matricula_denunciante,
                    informar_testemunha: params.row?.informar_testemunha,
                    assunto: params.row.assunto
                  }
                })
              }
            >
              Iniciar Chat
            </S.buttonInitChat>
          )
        if (params.row.status === 'ABERTO')
          return (
            <S.buttonOpen
              size="small"
              onClick={() =>
                navigate('/attendance/chat', {
                  state: {
                    id: params.row.id,
                    codigo: params.row.codigo,
                    usuario: params.row.usuario,
                    nickname: params.row?.nickname,
                    empresa: params.row.empresa,
                    dataPedido: params.row.dataPedido,
                    partnerChat: params.row.partnerChat,
                    partner: params.row.partner,
                    matricula: params.row.matricula,
                    filial: params.row.filial,
                    empresa_filha: params.row.empresa_filha,
                    departamento: params.row.departamento,
                    cargo: params.row.cargo,
                    email: params.row.email,
                    telefone: params.row.telefone,
                    data_admissao: params.row.data_admissao,
                    meio_ocorrencia: params.row.meio_ocorrencia,
                    periodicidade: params.row.periodicidade,
                    persiste: params.row.persiste,
                    provas: params.row.provas,
                    testemunhas: params.row.testemunhas,
                    anonimo: params.row.anonimo,
                    denunciado: params.row.denunciado,
                    nome_completo: params.row?.nome_completo,
                    matricula_denunciante: params.row?.matricula_denunciante,
                    informar_testemunha: params.row?.informar_testemunha,
                    assunto: params.row.assunto
                  }
                })
              }
            >
              Aberto
            </S.buttonOpen>
          )
        if (params.row.status === 'CONCLUIDO')
          return (
            <S.buttonFinished
              size="small"
              onClick={() =>
                navigate('/attendance/chat', {
                  state: {
                    id: params.row.id,
                    codigo: params.row.codigo,
                    usuario: params.row.usuario,
                    nickname: params.row?.nickname,
                    empresa: params.row.empresa,
                    dataPedido: params.row.dataPedido,
                    partnerChat: params.row.partnerChat,
                    partner: params.row.partner,
                    matricula: params.row.matricula,
                    filial: params.row.filial,
                    empresa_filha: params.row.empresa_filha,
                    departamento: params.row.departamento,
                    cargo: params.row.cargo,
                    email: params.row.email,
                    telefone: params.row.telefone,
                    data_admissao: params.row.data_admissao,
                    meio_ocorrencia: params.row.meio_ocorrencia,
                    periodicidade: params.row.periodicidade,
                    persiste: params.row.persiste,
                    provas: params.row.provas,
                    testemunhas: params.row.testemunhas,
                    anonimo: params.row.anonimo,
                    denunciado: params.row.denunciado,
                    nome_completo: params.row?.nome_completo,
                    matricula_denunciante: params.row?.matricula_denunciante,
                    informar_testemunha: params.row?.informar_testemunha,
                    assunto: params.row.assunto
                  }
                })
              }
            >
              Finalizado
            </S.buttonFinished>
          )
        if (
          params.row.status === 'NOVA MENSAGEM' ||
          params.row.status === 'NOVA_MENSAGEM'
        )
          return (
            <S.buttonNewMessage
              onClick={async () => {
                navigate('/attendance/chat', {
                  state: {
                    id: params.row.id,
                    codigo: params.row.codigo,
                    usuario: params.row.usuario,
                    nickname: params.row?.nickname,
                    empresa: params.row.empresa,
                    dataPedido: params.row.dataPedido,
                    partnerChat: params.row.partnerChat,
                    partner: params.row.partner,
                    matricula: params.row.matricula,
                    filial: params.row.filial,
                    empresa_filha: params.row.empresa_filha,
                    departamento: params.row.departamento,
                    cargo: params.row.cargo,
                    email: params.row.email,
                    telefone: params.row.telefone,
                    data_admissao: params.row.data_admissao,
                    meio_ocorrencia: params.row.meio_ocorrencia,
                    periodicidade: params.row.periodicidade,
                    persiste: params.row.persiste,
                    provas: params.row.provas,
                    testemunhas: params.row.testemunhas,
                    anonimo: params.row.anonimo,
                    denunciado: params.row.denunciado,
                    nome_completo: params.row?.nome_completo,
                    matricula_denunciante: params.row?.matricula_denunciante,
                    informar_testemunha: params.row?.informar_testemunha,
                    assunto: params.row.assunto
                  }
                })
              }}
            >
              Novo
            </S.buttonNewMessage>
          )
      }
    }
  ]

  const [rowsData, setRowsData] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const newChat = user => {
    const fetchUser = async () => {
      const response = await getUser(user.value)
      navigate('/attendance/chat', {
        state: {
          userId: response._id,
          usuario: response.username,
          nickname: response?.nickname,
          partner: user.ecossistema_fornecedor,
          matricula: response.matricula,
          email: response.email,
          telefone: response.telefone,
          data_admissao: response.data_admissao
        }
      })
    }
    fetchUser()
  }

  useEffect(() => {
    setLoading(true)
    const regexAssuntos = user.assuntos ? user.assuntos.join('|') : ''
    const payload = {
      ecossistema_fornecedor: user.ecossistema_fornecedor,
      assunto: regexAssuntos
    }
    getChatsFiltered(payload).then(res => {
      const newRows = res?.docs?.map(item => {
        return {
          id: item._id,
          codigo: item.tabela.codigo,
          usuario: item.tabela.usuario,
          nickname: item.usuario?.nickname,
          partnerChat: item.tipo_chat?._id,
          partner: item.tipo_chat?.partner,
          assunto: item.tipo_chat?.assunto,
          primeira_mensagem: item.tabela.primeira_mensagem,
          matricula: item.usuario?.matricula,
          empresa: item.tabela.empresa,
          empresa_filha: item.usuario?.empresa_filha,
          filial: item.usuario?.empresa_filial,
          departamento: item.usuario?.empresa_setor,
          cargo: item.usuario?.empresa_cargo,
          data_admissao: item.usuario?.data_admissao,
          email: item.usuario?.email,
          telefone: item.usuario?.telefone,
          dataPedido: moment(item.data_criacao).format('DD/MM/YYYY HH:mm'),
          status: item.tabela.status,
          meio_ocorrencia: item.tipo_chat.meio_ocorrencia,
          periodicidade: item.tipo_chat.periodicidade,
          persiste: item.tipo_chat.persiste,
          provas: item.tipo_chat.provas,
          testemunhas: item.tipo_chat.testemunhas,
          anonimo: item.tipo_chat.anonimo,
          denunciado: item.tipo_chat.denunciado,
          nome_completo: item.tipo_chat?.nome_completo,
          matricula_denunciante: item.tipo_chat?.matricula,
          informar_testemunha: item.tipo_chat?.informar_testemunha
        }
      })

      setRowsData(newRows || [])
      setLoading(false)
    })
  }, [])

  const handleChange = e => {
    const values = e.map(item => item.value)
    setValue('status', values)
  }

  const onSubmit = data => {
    setLoading(true)
    const regexAssuntos = user.assuntos ? user.assuntos.join('|') : ''

    const payload = {
      ecossistema_fornecedor: user.ecossistema_fornecedor,
      status_chat: data.status,
      codigo: data.codigo,
      username_matricula: data.usuario,
      empresa_razao: data.empresa,
      atendente: data.atendente,
      filial: data.filial,
      empresa_filha: data.empresa_filha,
      assunto: regexAssuntos,
      data_inicio: data.data_inicio
        ? moment(data.data_inicio, 'DD/MM/YYYY').utc().startOf('day').toDate()
        : undefined,
      data_fim: data.data_fim
        ? moment(data.data_fim, 'DD/MM/YYYY').utc().endOf('day').toDate()
        : undefined,
      anonimo: 'Não'
    }
    getChatsFiltered(payload)
      .then(res => {
        const newRows = res?.docs?.map(item => {
          return {
            id: item._id,
            codigo: item.tabela.codigo,
            usuario: item.tabela.usuario,
            nickname: item.usuario?.nickname,
            partnerChat: item.tipo_chat._id,
            partner: item.tipo_chat?.partner,
            assunto: item.tipo_chat?.assunto,
            matricula: item.usuario?.matricula,
            empresa: item.tabela.empresa,
            empresa_filha: item.usuario?.empresa_filha,
            filial: item.usuario?.empresa_filial,
            departamento: item.usuario?.setor,
            cargo: item.usuario?.cargo,
            data_admissao: item.usuario?.data_admissao,
            email: item.usuario?.email,
            telefone: item.usuario?.telefone,
            dataPedido: moment(item.data_criacao).format('DD/MM/YYYY HH:mm'),
            status: item.tabela.status,
            meio_ocorrencia: item.tipo_chat.meio_ocorrencia,
            periodicidade: item.tipo_chat.periodicidade,
            persiste: item.tipo_chat.persiste,
            provas: item.tipo_chat.provas,
            testemunhas: item.tipo_chat.testemunhas,
            anonimo: item.tipo_chat.anonimo,
            denunciado: item.tipo_chat.denunciado,
            nome_completo: item.tipo_chat?.nome_completo,
            matricula_denunciante: item.tipo_chat?.matricula,
            informar_testemunha: item.tipo_chat?.informar_testemunha
          }
        })

        setRowsData(newRows || [])
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)

        console.log(err)
      })
  }

  return (
    <S.Container>
      {showModal && (
        <NewChatModal
          setShowModal={setShowModal}
          showModal={showModal}
          setLoading={setLoading}
          newChat={newChat}
        />
      )}
      <S.Title>Atendimento</S.Title>
      {user.ecossistema_fornecedor !== '647e2dccd84caa388d2a6af3' && (
        <S.ButtonSearch
          style={{ backgroundColor: 'green' }}
          onClick={() => setShowModal(true)}
        >
          + Novo Chat
        </S.ButtonSearch>
      )}
      <S.WrapperInputs>
        <TextField
          {...register('codigo')}
          size="small"
          name="codigo"
          sx={{
            backgroundColor: '#fff'
          }}
          label="Código"
        />
        <TextField
          {...register('usuario')}
          size="small"
          name="usuario"
          sx={{
            backgroundColor: '#fff'
          }}
          label="Usuário/Matrícula"
        />
        {/* <TextField
          {...register('empresa')}
            size="small"
          name="empresa"
          sx={{
            backgroundColor: '#fff'
          }}
          label="Empresa"
        /> */}

        <TextField
          {...register('empresa_filha')}
          size="small"
          name="empresa_filha"
          sx={{
            backgroundColor: '#fff'
          }}
          label="Empresa"
        />

        <TextField
          {...register('filial')}
          size="small"
          name="filial"
          sx={{
            backgroundColor: '#fff'
          }}
          label="Filial"
        />
        {/* <TextField
          {...register('atendente')}
          size="small"
          name="atendente"
          sx={{
            backgroundColor: '#fff'
          }}
          label="Atendente"
        /> */}
        {/* <TextField
          {...register('assunto')}
          size="small"
          name="assunto"
          sx={{
            backgroundColor: '#fff'
          }}
          label="Assunto"
        /> */}
        <Autocomplete
          multiple
          size="small"
          options={options}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField
              sx={{ backgroundColor: '#fff' }}
              {...params}
              label="Status"
              placeholder="Selecione"
            />
          )}
          onChange={(event, value) => {
            handleChange(value)
          }}
        />
        <TextField
          {...register('data_inicio')}
          size="small"
          name="data_inicio"
          sx={{
            backgroundColor: '#fff'
          }}
          InputProps={{
            inputComponent: DateMaskCustom
          }}
          label="De:"
        />
        <TextField
          {...register('data_fim')}
          size="small"
          name="data_fim"
          sx={{
            backgroundColor: '#fff'
          }}
          InputProps={{
            inputComponent: DateMaskCustom
          }}
          label="Até:"
        />

        {/* <Controller
          render={({ field: { ref, onChange, value } }) => (
            <Autocomplete
              multiple
              options={status}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Status"
                  placeholder="Selecione"
                  onChange={onChange}
                  inputRef={params.ref}
                />
              )}
              onChange={(_, data) => console.log(data)}
              // onChange={(event, values, reason) => onChange(values)}
              value={value}
              ref={ref}
            />
          )}
          name="status"
          control={control}
          {...register('status')}
        /> */}
      </S.WrapperInputs>
      <S.ButtonSearch onClick={handleSubmit(onSubmit)}>Buscar</S.ButtonSearch>

      <DataGridAttendance rows={rowsData} columns={columns} loading={loading} />
    </S.Container>
  )
}

export default Attendance
