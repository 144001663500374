import React, { useEffect, useState } from 'react'
import * as S from './styles'
import SidebarItem from './SidebarItem'
import Logo from '../../assets/Sidebar/logo.png'
import { MdSupportAgent } from 'react-icons/md'
import { BsFillPersonFill } from 'react-icons/bs'
import { useAuth } from '../../contexts/auth'
import { getEcossistemaFornecedor } from '../../services/company'

function Sidebar({ setSidebar }) {
  const { isAdmin, user } = useAuth()
  const [ecossistema, setEcossistema] = useState(null)

  const closeSidebar = () => setSidebar(false)

  useEffect(() => {
    const fetchEcossistema = async () => {
      const response = await getEcossistemaFornecedor(
        user?.ecossistema_fornecedor
      )
      setEcossistema(response)
    }
    fetchEcossistema()
  }, [])

  return (
    <nav>
      <S.Container sidebar={setSidebar} onClick={closeSidebar}>
        <S.Close onClick={closeSidebar} />
        <S.Logo src={Logo} />
        <S.Title>Área administrativa</S.Title>

        <SidebarItem
          Icon={MdSupportAgent}
          text={'Atendimento'}
          path="/attendance"
        />

        {/* <SidebarItem
          Icon={MdMedicalServices}
          text={'Consultas'}
          path="/appointments"
        /> */}

        <SidebarItem Icon={BsFillPersonFill} text={'Conta'} path="/account" />

        {isAdmin ? (
          <SidebarItem
            Icon={BsFillPersonFill}
            text={'Usuários'}
            path="/users"
          />
        ) : null}

        {ecossistema?.upload_termos ? (
          <SidebarItem
            Icon={BsFillPersonFill}
            text={'Alunos'}
            path="/students"
          />
        ) : null}
      </S.Container>
    </nav>
  )
}

export default Sidebar
