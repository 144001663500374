import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Container = styled(NavLink)`
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: rgba(190, 92, 229, 0.15);
    border-left: 3px solid #7F5CD1;
    > svg {
      color: #7F5CD1;
    }
  }
  > svg {
    height: 18px;
    width: 18px;
    margin: 0 10px;
    color: #dbdde0;
  }

  &.active {
    background: rgba(190, 92, 229, 0.15);
    border-left: 3px solid #7F5CD1;
    > svg {
      color: #7F5CD1;
    }
  }
`

export const Text = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #52575c;
  ${Container}:hover & {
    color: #7F5CD1;
  }
  ${Container}.active & {
    color: #7F5CD1;
  }
`
