import api from './api'

//create user
export const createUser = async payload => {
  try {
    const response = await api.post('auth/partners', payload)
    return response
  } catch (error) {
    return error
  }
}

//get all users
export const getUsers = async partner => {
  try {
    const response = await api.get('auth/partners/' + partner + '/users')
    return response.data.response
  } catch (error) {
    return error
  }
}

export const getUser = async id => {
  try {
    const {data} = await api.get(`/auth/usuarios/${id}`)
    return data.response
  } catch (error) {
    return error
  }
}

export const getAllMatriculas = async (params) => {
  try {
    const response = await api.get('auth/usuarios/matriculas', { params })
    //const response = await Loader.show({ request })
    return response.data.response
  } catch (error) {
    return error
  }
}

//delete user
export const deleteUser = async partner => {
  try {
    const response = await api.delete('auth/partners/' + partner)
    return response
  } catch (error) {
    return error
  }
}

//update user
export const updateUser = async (partner, payload) => {
  try {
    const response = await api.put('auth/partners/' + partner, payload)
    return response
  } catch (error) {
    return error
  }
}

export const forgotPassword = async (payload) => {
  try {
    const response = await api.post('partners/esqueci-senha', payload)
    return response
  } catch (error) {
    return error
  }
}

export const resetPassword = async (payload) => {
  try {
    const response = await api.post('partners/alterar-senha', payload)
    return response
  } catch (error) {
    return error
  }
}

export const verifyEmail = async (payload) => {
  try {
    const response = await api.post('auth/partners/verificar-email', payload)
    return response
  } catch (error) {
    return error
  }
}
