import React, { useState } from 'react'
import Dropzone from 'react-dropzone'


import * as S from './styles'

function Modal(props) {
  const [file, setFile] = useState(null)



  return (
    <S.Container>
      <S.Modal>
        <S.Title>Faça upload do termo assinado</S.Title>
        <Dropzone onDrop={acceptedFiles => setFile(acceptedFiles[0])} accept={{'image/': ['.png', '.jpeg', '.jpg' ], 'text/': ['.pdf']}}>
          {({ getRootProps, getInputProps }) => (
            <S.Dropzone {...getRootProps()}>
              <input {...getInputProps()} />

              {!file ? (
                <S.DropzoneText>
                  Arraste e solte o arquivo aqui, ou clique para selecionar o
                  arquivo
                </S.DropzoneText>
              ) : (
                <S.DropzoneTextContainer>
                  <S.DropzoneIcon />
                  <S.DropzoneText>{file.name}</S.DropzoneText>
                </S.DropzoneTextContainer>
              )}
            </S.Dropzone>
          )}
        </Dropzone>
        <S.ButtonWrapper>
        <S.ButtonCancel onClick={() => props.setShowModal(false)}>
            Cancelar
          </S.ButtonCancel>
          <S.ButtonSend disabled={!file} onClick={() => props.uploadFile(file)}>
            Salvar
          </S.ButtonSend>
        </S.ButtonWrapper>
      </S.Modal>
    </S.Container>
  )
}

export default Modal
