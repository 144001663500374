import styled from 'styled-components'
import { FiAlertTriangle } from 'react-icons/fi'
import { Button } from '@mui/material'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(82, 87, 92, 0.36);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
`

export const Modal = styled.div`
  width: 560px;
  height: 308px;
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const IconAlert = styled(FiAlertTriangle)`
  color: #e43935;
  font-size: 80px;
`

export const Title = styled.h1`
  font-weight: 400;
  font-size: 18px;
  margin-top: 20px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
`
export const ButtonCancel = styled(Button).attrs({
  variant: 'outlined'
})`
  && {
    text-transform: none;
    width: 216px;
    height: 34px;
    border: 1px solid #c4c4c4;
    color: #212529;

    &:hover {
      opacity: 0.8;
      background: #c4c4c4;
      color: #212529;
      border: 1px solid #c4c4c4;
    }
  }
`

export const ButtonDelete = styled(Button).attrs({
  variant: 'contained'
})`
  && {
    text-transform: none;
    width: 216px;
    height: 34px;
    background: #e43935;
    color: #fff;

    &:hover {
      opacity: 0.8;
      background: #e43935;
      color: #fff;
    }
  }
`
