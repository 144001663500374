import React, { useContext } from 'react'

import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import { useAuth } from '../contexts/auth'

const Routes = () => {
  const { signed } = useAuth()

  return signed ? <PrivateRoutes /> : <PublicRoutes />
}

export default Routes
