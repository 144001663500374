import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DotLoader from 'react-spinners/DotLoader'
import { useAuth } from '../../contexts/auth'
import { toast } from 'react-toastify'
import { FaChevronLeft } from 'react-icons/fa'
import { forgotPassword } from '../../services/users'

function login() {
  const [loading, setLoading] = useState(false)
  const { login } = useAuth()

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Insira um e-mail válido')
      .required('E-mail é obrigatório')
  })

  const {
    register,
    handleSubmit,
    formState,
    formState: { isSubmitSuccessful },
    formState: { errors },
    reset,
    setError
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    setLoading(true)
    const response = await forgotPassword({ email: data.email })
    if (response.status === 200) {
      toast.success(response.data.response.message)
    } else toast.error(response?.response?.data?.message)
    setLoading(false)
  }

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ email: '' })
    }
  }, [formState, reset])

  useEffect(() => {
    const tokenExpired = window.location.search.includes('tokenExpired')
    if (tokenExpired) {
      toast.error('Sua sessão expirou, faça login novamente')
    }
  }, [])

  return (
    <S.Container>
      <S.ContentLeft>
        <S.Content></S.Content>
      </S.ContentLeft>
      <S.ContentRight>
        <S.LoginWrapper onSubmit={handleSubmit(onSubmit)}>
          <S.LoginTitle to="/">
            <FaChevronLeft size={16} />
            &nbsp; Redefinir sua senha <br />
            <br />
          </S.LoginTitle>
          <S.Text>
            Preencha o seu email para enviarmos um link de redefinição de senha.
          </S.Text>
          <S.Input
            {...register('email')}
            type="email"
            placeholder="E-mail"
            name="email"
          />
          <S.Error>{errors.email?.message}</S.Error>

          <S.Button disabled={false} onClick={handleSubmit}>
            {loading ? <DotLoader size={30} color="#fff" /> : 'Enviar'}
          </S.Button>
        </S.LoginWrapper>
      </S.ContentRight>
    </S.Container>
  )
}

export default login
