import styled from 'styled-components'
import { FaBars } from 'react-icons/fa'

export const Container = styled.div`
  width: 100%;
  min-height: 60px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`

export const ButtonExit = styled.button`
  color: #808080;
  font-weight: 500;
  font-size: 14px;
  margin-right: 30px;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
`
export const Hamburguer = styled(FaBars)`
  color: #000000;
  font-size: 30px;
  cursor: pointer;
  margin-left: 30px;
  color: #808080;
  width: 30px;
  height: 30px;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
`
