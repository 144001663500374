import React, {useEffect, useState} from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../contexts/auth'

import * as S from './styles'

import { getAllMatriculas } from '../../../services/users'
import { getEcossistemaCompanies } from '../../../services/company'
import { Autocomplete, TextField } from '@mui/material'



function Modal(props) {
  const { user } = useAuth()

  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => { 
    const fetch = async () => {
      await getEcossistemaCompanies(user.ecossistema_fornecedor).then(async res => {
        const usersResponse = await getAllMatriculas({
          empresa: res[0]?.empresa._id,
          situacao: 'ATIVO',
          acesso_testes: false
        })
    
        const matriculas = usersResponse
          .filter(u => u.matricula ?? u.username !== '')
          .map(u => ({ value: u._id, label: u.matricula ?? u.username }))
    
        setUsers(matriculas || [])
      })

 
  }
  fetch()
  }
  , [])

  return (
    <S.Container>
      <S.Modal>
      <Autocomplete
          multiple={false}
          options={users}
          getOptionLabel={option => option.label}
          disablePortal={true}
          renderInput={params => (
            <TextField
              sx={{
                backgroundColor: '#fff',
                width: 400,
                marginBottom: 3,
              }}
              {...params}
              label="Matrícula"
              placeholder="Selecione"
            />
          )}
          onChange={(event, value) => {
            setSelectedUser(value)
          }}
        />
        <S.ButtonWrapper>
          <S.ButtonCancel onClick={() => props.setShowModal(false)}>
            Cancelar
          </S.ButtonCancel>
          <S.ButtonDelete disabled={!selectedUser} onClick={() => props.newChat(selectedUser)}>Criar Chat</S.ButtonDelete>
        </S.ButtonWrapper>
      </S.Modal>
    </S.Container>
  )
}

export default Modal
