import React, { useState } from 'react'
import * as S from './styles'

function SidebarItem({ Icon, text, path }) {
  return (
    <S.Container to={path}>
      <Icon />
      <S.Text>{text}</S.Text>
    </S.Container>
  )
}

export default SidebarItem
