import api from './api'

export const getStudents = async empresa => {
  try {
    const { data } = await api.get(
      `/auth/dulce/partners/alunos?empresa=${empresa}`
    )
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}

export const uploadFile = async (responsavel, formData) => {

  try {
    const { data } = await api.post(
      `/auth/dulce/partners/upload-termo?responsavel_aluno=${responsavel}&versao_termo=1`, formData
    )
    return data?.response
  } catch (error) {
    return error?.response?.data
  }
}
