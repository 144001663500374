import React from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import { MdOutgoingMail } from 'react-icons/md/'
import { MdDrafts } from 'react-icons/md/'
import { MdMarkEmailRead } from 'react-icons/md/'
import { MdMarkEmailUnread } from 'react-icons/md/'

export const Container = styled.div`
  /* border: 2px solid red; */
  width: 100%;
`
export const Title = styled.h1`
  font-weight: 600;
  font-size: 22px;
`

export const WrapperInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
`

export const ButtonSearch = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 29px;

  background: #7F5CD1;
  border-radius: 5px;
  border: none;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const buttonInitChat = styled(Button).attrs({
  variant: 'outlined',
  startIcon: <MdOutgoingMail />,
  color: 'primary'
})`
  && {
    text-transform: none;
    width: 100%;
    height: 27px;
  }
`
export const buttonOpen = styled(Button).attrs({
  // variant: 'outlined',
  startIcon: <MdDrafts />,
  color: 'primary'
})`
  && {
    text-transform: none;
    width: 100%;
    height: 27px;
    background: #28a745;
    color: #fff;

    &:hover {
      opacity: 0.8;
      background: #28a745;
      color: #fff;
    }
  }
`
export const buttonFinished = styled(Button).attrs({
  // variant: 'outlined',
  startIcon: <MdMarkEmailRead />,
  color: 'primary'
})`
  && {
    text-transform: none;
    /* width: 130px; */
    height: 27px;
    width: 100%;
    background: #808080;
    color: #fff;

    &:hover {
      opacity: 0.8;
      background: #808080;
      color: #fff;
    }
  }
`

export const buttonNewMessage = styled(Button).attrs({
  // variant: 'outlined',
  startIcon: <MdMarkEmailUnread />,
  color: 'primary'
})`
  && {
    text-transform: none;
    width: 100%;
    height: 27px;
    background: #007bff;
    color: #fff;

    &:hover {
      background: #007bff;
      color: #fff;
      opacity: 0.8;
    }
  }
`
