import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import Account from '../pages/Account'
import Attendance from '../pages/Attendance'
import Appointments from '../pages/Appointments'
import Users from '../pages/Users'
import Chat from '../pages/Attendance/Chat'
import EditUser from '../pages/Users/EditUser'
import AddUser from '../pages/Users/AddUser'
import Students from '../pages/Students'

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Attendance />} />
        <Route path="attendance" element={<Attendance />} />
        <Route path="attendance/chat" element={<Chat />} />

        <Route exact path="dashboard" element={<Dashboard />} />
        <Route exact path="account" element={<Account />} />
        <Route exact path="appointments" element={<Appointments />} />
        <Route exact path="users" element={<Users />} />
        <Route exact path="users/add" element={<AddUser />} />
        <Route exact path="users/edit" element={<EditUser />} />
        <Route exact path="students" element={<Students />} />
        <Route exact path="*" element={<h1>Not Found</h1>} />
      </Route>
    </Routes>
  )
}

export default PublicRoutes
