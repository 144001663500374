import axios from 'axios'

export const getBaseUrl = () => {
  const env = process.env.REACT_APP_ENV
  switch (env) {
    case 'prod':
      return 'https://api.hspw.com/'
    case 'hml':
      return 'https://api-qa.hspw.com/'
    default:
      return 'http://localhost:3001/'
  }
}

console.log(process.env.REACT_APP_ENV)

const api = axios.create({
  baseURL: getBaseUrl()
})

//set token
// api.interceptors.request.use(
//   config => {
//     const token = localStorage.getItem('@Partner:token')
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`
//     }
//     return config
//   },
//   error => {
//     console.log(error)
//     return Promise.reject(error)
//   }
// )

api.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response.status === 499) {
      localStorage.removeItem('@Partner:token')
      localStorage.removeItem('@Partner:user')
      window.location.href = '/?tokenExpired=true'
    }

    return Promise.reject(error)
  }
)

export default api
